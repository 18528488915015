import { atom } from 'jotai';

export const checkedAtom = atom({});
export const offsetAtom = atom(0);

export const institutionAtom = atom([]);
export const selectedInstitutionAtom = atom(0);

export const errorPrefCodeAtom = atom('');
export const errorDummySideDeptAtom = atom('');
export const errorDummySidePostAtom = atom('');
export const errorDummySideTelAtom = atom('');
export const errorDummySideEmailAtom = atom('');
export const errorDummySidePassAtom = atom('');

export const errorUserNameAtom = atom('');
export const errorUserDeptNameAtom = atom('');
export const errorUserPostAtom = atom('');
export const errorTelephoneNumberAtom = atom('');
export const errorEmailAtom = atom('');
export const errorPasswordAtom = atom('');

export const errorFileNameAtom = atom('');
export const errorChecklistNameAtom = atom('');
export const errorFileUuidAtom = atom('');

export const isLoadingAtom = atom(false);
