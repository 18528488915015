import React from 'react';

import { useAtomValue } from 'jotai';
import { isLoadingAtom } from 'jotai/atoms';
import { Oval } from 'react-loader-spinner';

export const Loading: React.FC = () => {
  const isLoading = useAtomValue(isLoadingAtom);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100vh',
            zIndex: '9999',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Oval height={220} width={220} color='#7BD957' strokeWidth={3} />
          </div>
        </div>
      ) : null}
    </>
  );
};
