import React, { useContext } from 'react';

import { Box, Dialog, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InspectionDepartmentContext } from 'views/pages/InspectionDepartment';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';

import { TransferList } from 'views/components/transferList';

const deptLvName = [
  'dummy',
  '階層レベルⅠ',
  '階層レベルⅡ',
  '階層レベルⅢ',
  '階層レベルⅣ',
];

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
    minHeight: '65vh',
    maxHeight: '65vh',
  },
});

const SettingDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
  data;
}> = (props) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    selectedFiscalYear,
    datas,
    checkedInfo,
    selectedDeptLv,
    isDialogOpen,
    setIsDialogOpen,
  } = useContext(InspectionDepartmentContext);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box component='span'>
      <DialogButtonCreate
        buttonLabel='閲覧・検証設定'
        onClickActionHandler={handleOpen}
        isDisabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='閲覧・検証設定'
          onClickActionHandler={handleClose}
        />

        <div style={{ fontSize: 18, textAlign: 'center', padding: 16 }}>
          {deptLvName[selectedDeptLv]}&ensp;
          {checkedInfo.dept_name_lv1}
          {checkedInfo.dept_name_lv2}&ensp;
          {checkedInfo.dept_name_lv3}&ensp;
          {checkedInfo.dept_name_lv4}&ensp;
          {checkedInfo.dept_name_lv5}
        </div>

        <Divider />

        <TransferList
          selectedFiscalYear={selectedFiscalYear}
          pref_code={pref_code}
          bank_code={bank_code}
          lv={selectedDeptLv}
          data={datas.orgData}
          checkedInfo={checkedInfo}
          setIsDialogOpen={setIsDialogOpen}
        />
      </Dialog>
    </Box>
  );
};

export default SettingDialog;
