import React, { useState } from 'react';

import axios from 'axios';

import { Box, Dialog, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChecklistVerificationContext } from 'views/pages/ChecklistVerification';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';

import { useSetAtom } from 'jotai';
import { isLoadingAtom } from 'jotai/atoms';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const ConfirmDialog: React.FC<{
  isDialogOpen;
  setIsDialogOpen;
  yearOption;
}> = (props) => {
  const classes = useStyles();

  const {
    dispData,
    mapVerificationHist,
    setMapVerificationHist,
    user,
    dept_code_lv2,
    dept_code_lv3,
    dept_code_lv4,
    dept_code_lv5,
  } = React.useContext(ChecklistVerificationContext);

  const { isDialogOpen, setIsDialogOpen, yearOption } = props;

  const setIsLoading = useSetAtom(isLoadingAtom);
  const [isDiabled, setIsDisabled] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const getDept = () => {
    if (dept_code_lv2 === 999) {
      return <>検査部署：全部署</>;
    } else if (dept_code_lv3 === 999) {
      return <>検査部署：{dispData[0].dept_name_lv2}&ensp;全部署</>;
    } else if (dept_code_lv4 === 999) {
      return (
        <>
          検査部署：{dispData[0].dept_name_lv2}&ensp;{dispData[0].dept_name_lv3}
          &ensp;全部署
        </>
      );
    } else if (dept_code_lv5 === 999) {
      return (
        <>
          検査部署：{dispData[0].dept_name_lv2}&ensp;{dispData[0].dept_name_lv3}
          &ensp;{dispData[0].dept_name_lv4}
          &ensp;全部署
        </>
      );
    } else {
      return (
        <>
          検査部署：{dispData[0].dept_name_lv2}&ensp;
          {dispData[0].dept_name_lv3}&ensp;
          {dispData[0].dept_name_lv4}&ensp;
          {dispData[0].dept_name_lv5}
        </>
      );
    }
  };

  const handleApproveAll = async () => {
    setIsLoading(true);
    setIsDisabled(true);

    let tmp;

    await Promise.all(
      dispData
        .filter((x) => {
          const key = [
            x.fiscal_year,
            x.pref_code,
            x.bank_code,
            x.inspection_month,
            x.category_code,
            x.subcategory_code,
            x.department_level_code,
            x.dept_code_lv2,
            x.dept_code_lv3,
            x.dept_code_lv4,
            x.dept_code_lv5,
            user.department_level_code,
            user.dept_code_lv1,
            user.dept_code_lv2,
            user.dept_code_lv3,
            user.dept_code_lv4,
            user.dept_code_lv5,
          ].join(':');

          return !mapVerificationHist.has(key);
        })
        .map(async (x) => {
          tmp = await axios.post('/api/checklist-confirm/approve-checklist', {
            fiscal_year: x.fiscal_year,
            pref_code: x.pref_code,
            bank_code: x.bank_code,
            inspection_month: x.inspection_month,
            category_code: x.category_code,
            subcategory_code: x.subcategory_code,
            department_level_code: x.department_level_code,
            dept_code_lv2: x.dept_code_lv2,
            dept_code_lv3: x.dept_code_lv3,
            dept_code_lv4: x.dept_code_lv4,
            dept_code_lv5: x.dept_code_lv5,
            user,
          });
        })
    );

    tmp.data.hist.forEach((x) => {
      const key = [
        x.fiscal_year,
        x.pref_code,
        x.bank_code,
        x.inspection_month,
        x.category_code,
        x.subcategory_code,
        x.department_level_code,
        x.dept_code_lv2,
        x.dept_code_lv3,
        x.dept_code_lv4,
        x.dept_code_lv5,
        x.verified_department_level_code,
        x.verified_dept_code_lv1,
        x.verified_dept_code_lv2,
        x.verified_dept_code_lv3,
        x.verified_dept_code_lv4,
        x.verified_dept_code_lv5,
      ].join(':');

      mapVerificationHist.set(key, x.is_approved);
    });

    setMapVerificationHist(new Map(mapVerificationHist));

    setIsDialogOpen(false);
    setIsLoading(false);
  };

  return (
    <Box component='span' style={{ marginLeft: 8 }}>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={'md'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='検査結果の一括承認'
          onClickActionHandler={handleClose}
        />
        <Divider />

        <div style={{ marginTop: 32, marginLeft: 32 }}>
          <div style={{ fontSize: 16, margin: 0 }}>
            検査年度：{yearOption[0].val}
          </div>
          <div style={{ fontSize: 16, marginTop: 8 }}>{getDept()}</div>
          <div style={{ fontSize: 16, marginTop: 8 }}>
            対象チェックリスト数：
            {
              dispData.filter((x) => {
                const key = [
                  x.fiscal_year,
                  x.pref_code,
                  x.bank_code,
                  x.inspection_month,
                  x.category_code,
                  x.subcategory_code,
                  x.department_level_code,
                  x.dept_code_lv2,
                  x.dept_code_lv3,
                  x.dept_code_lv4,
                  x.dept_code_lv5,
                  user.department_level_code,
                  user.dept_code_lv1,
                  user.dept_code_lv2,
                  user.dept_code_lv3,
                  user.dept_code_lv4,
                  user.dept_code_lv5,
                ].join(':');

                return !mapVerificationHist.has(key);
              }).length
            }
          </div>
        </div>

        <DialogActionsCreate
          buttonLabel='一括承認'
          onClickActionHandler={handleApproveAll}
          disabled={isDiabled}
        />
      </Dialog>
    </Box>
  );
};

export default ConfirmDialog;
