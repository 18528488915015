import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Router from 'router';

import { Loading } from './views/components/Loading';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Loading />
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);
